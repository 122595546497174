/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';
import { convertUrlToAbsolute } from './../../helpers/convertUrlToAbsolute';

const externalLinkClick = () => {
  $(document).on('click', 'a[target=_blank]', event => {
    const url = event.currentTarget.href;
    const label = $.trim($(event.currentTarget).text());
    pushToDataLayer({ event: 'exit-link-new-window', label, url });
  });
}

const linkClick = () => {
  $('a').not('[href="javascript:void(0);"], [href^="tel:"], [href^="mailto:"]').on('click', event => {
    const $target = $(event.currentTarget);
    const targetURL = convertUrlToAbsolute($target.attr('href'));
    const clickedText = $target.text().trim();
    const linkUrlObject = new URL(targetURL);
    // Remove 'www.' prefix from the hostname
    const linkUrlHostname = linkUrlObject.hostname.replace(/^www\./, '');
    const locationHostname = window.location.hostname.replace(/^www\./, '');
    const outbound = locationHostname !== linkUrlHostname;

    pushToDataLayer({
      event: 'ga4_click',
      ga4_data: {
        link_url: targetURL,
        link_domain: linkUrlHostname,
        link_text: clickedText,
        outbound: outbound
      }
    });
  });
}

const externalPopupVideoClick = () => {
  $('.js-video-youtube').on('click', event => {
    pushToDataLayer({
      event: 'engagement',
      action: 'video-play',
      url: $(event.currentTarget).attr('href')
    });
  })
}

const clickOnEmailAddress = () => {
  $('a[href*="mailto:"]').on('click', () => {
    pushToDataLayer({
      event: 'ga4_email_click'
    });
  });
}

const clickOnPhoneNumber = () => {
  $('a[href*="tel:"]').on('click', () => {
    pushToDataLayer({
      event: 'ga4_phone_click'
    });
  });
}

const openSampleForm = () => {
  $('.ga4-sample-form').on('click', event => {
    const $target = $(event.currentTarget);

    pushToDataLayer({
      event: 'ga4_sample_form_cta',
      ga4_data: {
        link_text: $target.text().trim(),
        link_url: convertUrlToAbsolute($target.attr('href'))
      }
    })
  })
}

export const addGlobalListeners = () => {
  externalLinkClick();
  linkClick();
  externalPopupVideoClick();
  clickOnEmailAddress();
  clickOnPhoneNumber();
  openSampleForm();
};
