/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';

let formInteraction = false;

/**
 * Handles the interaction with the review form.
 *
 * @param {String} $productId - The ID of the product.
 * @param {String} $productName - The name of the product.
 */
const reviewFormInteractionListener = ($productId, $productName) => {
  $(document).on('input', '.bv-text, .pr-textinput, .pr-textarea', () => {
    if (!formInteraction) {
      pushToDataLayer({
        event: 'ga4_review',
        ga4_data: {
          action_type: 'Start to write a review',
          single_item_id: $productId,
          single_item_name: $productName
        }
      });

      formInteraction = true;
    }
  });
}

/**
 * Handles the submission of a review form.
 *
 * @param {String} $productId - The ID of the product being reviewed.
 * @param {String} $productName - The name of the product being reviewed.
 */
const reviewFormSubmissionListener = ($productId, $productName) => {
  $(document).on('click', '.pr-btn-review', () => {
    pushToDataLayer({
      event: 'ga4_review',
      ga4_data: {
        action_type: 'Submit a review',
        single_item_id: $productId,
        single_item_name: $productName
      }
    });
  });
}

/**
 * Handles the click event for the "Write a review" CTA.
 * Call reviewFormInteractionListener and reviewFormSubmissionListener
 * functions to handle the form interaction and submission listeners after review button click.
 */
const writeReviewCtaClick = () => {
  const $productId = $('.js-product-intro-item').data('product-id');
  const $productName = $('.js-product-intro-item').data('product-name');
  const shadowHost = document.querySelector('[data-bv-show="reviews"]');
  const maxRetries = 3;
  let retries = 0;

  // DataLayer push on "Write a review" CTA event for PowerReviews.
  $(document).on('click', '.pr-snippet-write-review-link', () => {
    pushToDataLayer({
      event: 'ga4_review',
      ga4_data: {
        action_type: 'Write a review cta click',
        single_item_id: $productId,
        single_item_name: $productName
      }
    });

    reviewFormInteractionListener($productId, $productName);
    reviewFormSubmissionListener($productId, $productName);
  });

  // DataLayer push on "Write a review" CTA event for PowerReviews on pushbox component.
  $(document).on('click', '.ga-pushbox-link', event => {
    const $target = $(event.currentTarget);
    // Check if the href attribute contains 'write-a-review'
    const isReviewLink = /write-a-review/.test($target.attr('href'));

    if (isReviewLink) {
      const productIdPushbox = $target.data('product-id');
      const productNamePushbox = $target.data('product-name');

      // Store product ID and name in session storage for use on the powerReview page.
      sessionStorage.setItem('productIdPushbox', productIdPushbox);
      sessionStorage.setItem('productNamePushbox', productNamePushbox);

      pushToDataLayer({
        event: 'ga4_review',
        ga4_data: {
          action_type: 'Write a review cta click',
          single_item_id: productIdPushbox,
          single_item_name: productNamePushbox
        }
      });
    }
  });

  /**
   * Check for shadow DOM and set up star click handlers.
   * DataLayer push on "Write a review" CTA event for BazzarVoice.
   */
  if (shadowHost) {
    const intervalId = setInterval(() => {
      retries++;
      if (shadowHost.shadowRoot) {
        const shadowRoot = shadowHost.shadowRoot;
        const $reviewStars = $(shadowRoot).find('.bv-reviews-product-rating-star');

        if ($reviewStars.length > 0) {
          clearInterval(intervalId);
          $reviewStars.each((index, element) => {
            $(element).on('click', () => {
              pushToDataLayer({
                event: 'ga4_review',
                ga4_data: {
                  action_type: 'Write a review cta click',
                  single_item_id: $productId,
                  single_item_name: $productName
                }
              });

              reviewFormInteractionListener($productId, $productName);
              reviewFormSubmissionListener($productId, $productName);
            });
          });
        }
      }

      if (retries >= maxRetries) {
        clearInterval(intervalId);
      }
    }, 1000);
  }
}

export const addProductReviewsEvents = () => {
  $(document).ready(() => {
    // Retrieve product ID and name from session storage to use on the PowerReviews page.
    const productIdPushbox = sessionStorage.getItem('productIdPushbox');
    const productNamePushbox = sessionStorage.getItem('productNamePushbox');

    // If product ID and name exist in session storage, initialize review form interactions.
    if (productIdPushbox && productNamePushbox) {
      reviewFormInteractionListener(productIdPushbox, productNamePushbox);
      reviewFormSubmissionListener(productIdPushbox, productNamePushbox);
    }

    writeReviewCtaClick();
  });
};
