/* eslint-disable camelcase */
import { getUrl } from '../../helpers/getUrl';
import { pushToDataLayer } from '../pushToDataLayer';
import { convertUrlToAbsolute } from '../../helpers/convertUrlToAbsolute';

const buyNowClickOnProductDetailPage = () => {
  $(document).on('click', '.ga-buy-now-button', (event) => {
    const $link = $(event.currentTarget);
    const productName = $link.data('productname');
    const $productID = $link.data('productid');

    pushToDataLayer({
      event: 'buy-now-button',
      label: productName
    });

    if (!$link.parents('.js-click-to-buy-container').length) {
      pushToDataLayer({
        event: 'gaEvent',
        eventTrigger: 'buyNowClick',
        eventCategory: 'Buy Now - Retailer Click - Retailer Domain',
        eventAction: productName,
        eventLabel: $link.data('siteurl')
      });

      pushToDataLayer({
        event: 'gaEvent',
        eventTrigger: 'buyNowClick',
        eventCategory: 'Buy Now - Retailer Click - Page URL',
        eventAction: productName,
        eventLabel: getUrl(window.location)
      });

      pushToDataLayer({
        event: 'ga4_buy_now_go_to_retailer',
        ga4_data: {
          retailer: $link.data('retailer-name'),
          single_item_id: $productID,
          single_item_name: productName
        }
      });
    }
  });
}

const productFindeoutProductSelect = () => {
  $(document).on('click', '.js-product-findout', event => pushToDataLayer({
    event: 'engagement',
    action: 'findout',
    label: $(event.currentTarget).data('productname')
  }));
}

const buyNowRetailerClick = () => {
  $(document).on('click', '.ga-buyNow-retailerClick', event => {
    const $link = $(event.currentTarget);
    const productName = $link.data('productname');

    pushToDataLayer({
      event: 'gaEvent',
      eventTrigger: 'buyNowClick',
      eventCategory: 'Buy Now - Retailer Click - Retailer Domain',
      eventAction: productName,
      eventLabel: $link.data('retailer-name')
    });

    pushToDataLayer({
      event: 'gaEvent',
      eventTrigger: 'buyNowClick',
      eventCategory: 'Buy Now - Retailer Click - Page URL',
      eventAction: productName,
      eventLabel: getUrl(window.location)
    });

    pushToDataLayer({
      event: 'ga4_buy_now_go_to_retailer',
      ga4_data: {
        retailer: $link.data('retailer-name'),
        single_item_id: $link.data('productid'),
        single_item_name: productName
      }
    });
  });
}

const buyNowClic2Buy = () => {
  $(document).on('click', '.ga-click-to-buy', event => {
    const $link = $(event.currentTarget);

    pushToDataLayer({
      event: 'gaEvent',
      eventTrigger: 'buyNowClick',
      eventCategory: 'Buy Now - List Retailers',
      eventAction: $link.data('productname'),
      eventLabel: getUrl(window.location)
    });

    pushToDataLayer({
      event: 'ga4_buy_now_list_retailers',
      ga4_data: {
        single_item_id: $link.data('productid'),
        single_item_name: $link.data('productname')
      }
    });
  });
}

const buyNowListRetailers = () => {
  $(document).on('click', '.ga-buy-now-popup', event => {
    const $link = $(event.currentTarget);

    pushToDataLayer({
      event: 'gaEvent',
      eventTrigger: 'buyNowClick',
      eventCategory: 'Buy Now - List Retailers',
      eventAction: $link.data('productname'),
      eventLabel: getUrl(window.location)
    });

    pushToDataLayer({
      event: 'ga4_buy_now_list_retailers',
      ga4_data: {
        single_item_id: $link.data('productid'),
        single_item_name: $link.data('productname')
      }
    });
  });
}

const whereToBuyRetailerClick = () => {
  $(document).on('click', '.buyNowList a', event => {
    let targetURL = $(event.currentTarget).attr('href');

    if (targetURL !== '') {
      targetURL = targetURL.startsWith('http://') || targetURL.startsWith('https://') || targetURL.startsWith('www.')
        ? targetURL : 'www.' + targetURL;
      if (targetURL.indexOf('?') > 0) {
        targetURL = targetURL.split('?')[0];
      }

      pushToDataLayer({
        event: 'gaEvent',
        eventTrigger: 'buyNowClick',
        eventCategory: 'Buy Now - Where to buy',
        eventAction: window.location.href,
        eventLabel: targetURL
      });
    }
  });
}

const productCardClick = () => {
  $(document).on('click', '.ga-product-click', event => {
    const $currentTarget = $(event.currentTarget);
    const $target = $(event.target);
    const $btn = $target.closest('.btn');
    const $title = $currentTarget.find('.title').text().trim();
    // Default value is $title
    let clickedText = $title;
    const targetURL = convertUrlToAbsolute(event.currentTarget.href);

    // If the button has the class 'ga-buy-now-button' or 'ga-buy-now-popup', stop execution without pushing data to the DataLayer.
    if ($btn.is('.ga-buy-now-button, .ga-buy-now-popup')) {
      return;
    }

    // If the clicked target is an image or btn
    if ($target.is('img')) {
      clickedText = $target.attr('title');
    } else if ($btn.length > 0) {
      clickedText = $btn.text().trim();
    }

    pushToDataLayer({
      event: 'ga4_product_click',
      ga4_data: {
        single_item_id: $currentTarget.data('productid'),
        single_item_name: $currentTarget.data('productname'),
        link_text: clickedText,
        link_url: targetURL
      }
    });
  });
}

export const addProductListeners = () => {
  buyNowClic2Buy();
  buyNowClickOnProductDetailPage();
  buyNowListRetailers();
  buyNowRetailerClick();
  productFindeoutProductSelect();
  whereToBuyRetailerClick();
  productCardClick();
}
