/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';

/**
 * DataLayer push on file download click event.
 * This function is used also for promo box click event (promoBoxListeners.js).
 * @param {Event} event - The click event object.
 */
export const clickOnFileDownload = (event) => {
  let linkText;
  let targetHref;
  const textNode = 3;
  const $currentTarget = $(event.currentTarget);
  const $href = $currentTarget.attr('href');
  const allowedFileTypes = ['pdf', 'doc', 'docx', 'xml', 'csv', 'xls', 'xlsx', 'txt', 'ppt', 'pptx', 'zip', 'rar'];
  // Validate if a given filename has one of these extensions
  const fileRegex = new RegExp(`\\.(${allowedFileTypes.join('|')})(\\?.*)?$`, 'i');
  const linkMappings = [
    {
      linkClass: 'pushbox-link',
      textClass: '.title'
    },
    {
      linkClass: 'carousel-slide-link',
      textClass: '.carousel-title'
    },
    {
      linkClass: 'intro-carousel-link',
      textClass: '.intro-carousel-title'
    }
  ]

  if(fileRegex.test($href)) {

    try {
      targetHref = new URL($currentTarget.attr('href'));
    } catch (error) {
      return;
    }

    const matchedMapping = linkMappings.find(el => $currentTarget.hasClass(el.linkClass));
    const fileExtension = targetHref.pathname.split('.').pop();

    if(matchedMapping) {
      linkText = $currentTarget.find(matchedMapping.textClass).text().trim();
      linkText = linkText === '' ? $currentTarget.parent().find(matchedMapping.textClass).text().trim() : linkText;
    } else {
      linkText = $($currentTarget).contents()
      .map(function() {
          return this.nodeType === textNode ? this.nodeValue.trim() : $(this).text().trim();
      })
      .get()
      .join('');
    }

    /*
    - Matches the last "/" followed by the filename (excluding "/").
    - Captures the filename before the extension.
    - Matches a dot "." before the allowed file extensions.
    - Ensures the extension is one of the listed ones.
    - Ends at the string boundary "$".
    - Case-insensitive "i".
    */
    const fileNameRegex = new RegExp('\\/([^/]+)\\.(pdf|doc|docx|xml|csv|xls|xlsx|txt|ppt|pptx|zip|rar)$', 'i');
    const locationHostname = window.location.hostname.replace(/^www\./, '');

    if (allowedFileTypes.includes(fileExtension)) {
      pushToDataLayer({
        event: 'ga4_file_download',
        ga4_data: {
          file_extension: fileExtension.toUpperCase(),
          file_name: targetHref.href.match(fileNameRegex)[1],
          link_text: linkText,
          link_url: targetHref.href,
          file_type: 'General download',
          link_domain: locationHostname,
        }
      });
    }
  }
}

export const addFileDownloadListeners = () => {
  // File download event listener for:
  // item box (item-box.hbs),
  // call to action (call-to-action-item.hbs),
  // product-intro (product-intro-content.hbs),
  // footer (footer-column-item.hbs),
  // campaign (campaign-image-content.hbs).
  $('a').on('click', event => clickOnFileDownload(event));
};
